import React, { memo } from 'react'
import { faHome } from '@fortawesome/pro-solid-svg-icons'

import { BWIcon } from '../../../libs/qb-brand-web-components'
import NavHeader from '../../../components/navHeader/NavHeader'

const NavHeaderWrapper = (props) => {
  return <NavHeader {...props} logoTitle={<BWIcon icon={faHome} />} />
}

export default memo(NavHeaderWrapper)
