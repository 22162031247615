import React, { memo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { BrandLogoContainer, BWIcon } from 'src/libs/qb-brand-web-components'
import { faHome } from '@fortawesome/pro-solid-svg-icons'

// import brandLogo from 'assets/logo.png'
import brandEaglesLogo from 'assets/eagles-logo.png'
import { isWelcomeRefMatching } from '../util/brand.helpers'

const UnauthHeaderLogoWrapper = ({ customCss }) => {
  const [searchParams] = useSearchParams()
  const isUniqueWelcome = isWelcomeRefMatching(searchParams.get('partner'))
  return isUniqueWelcome ? (
    <BrandLogoContainer
      src={
        // isUniqueWelcome
        //  ? brandEaglesLogo
        //  : brandLogo
        brandEaglesLogo
      }
      css={[customCss]}
    />
  ) : (
    <BWIcon icon={faHome} iconColor='accent' faIconCss={{ height: 30 }} />
  )
}

export default memo(UnauthHeaderLogoWrapper)
