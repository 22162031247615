import React, { memo } from 'react'
import { Route } from 'react-router-dom'

import HomeContainer from '../../../../containers/HomeContainer'
import { EARN_POINTS_URL } from '../../../../constants/navigation'
import EarnPointsContainer from '../../containers/EarnPointsContainer'

const HomeContainerWrapper = (props) => {
  return (
    <HomeContainer
      {...props}
      additionalRoutes={
        <Route path={EARN_POINTS_URL} element={<EarnPointsContainer />} />
      }
    />
  )
}

export default memo(HomeContainerWrapper)
