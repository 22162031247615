import React, { memo } from 'react'

import Popup from '../../../../components/shared/popup/Popup'
import RedeemAsset from '../../../../components/redeem/RedeemAsset'
import brandConfig from 'brandConfig'

const ViewEarnReward = ({ onCancel, onSubmit, t, label, image }) => {
  return (
    <Popup
      submitLabel={t('brand:welcome.earn-points')}
      onFormSubmit={onSubmit}
      cancelLabel={t('common.close')}
      onCancel={onCancel}
      title={t(`brand:earn_rewards.${label}.title`)}
      description={t(`brand:earn_rewards.${label}.description`)}
    >
      <RedeemAsset
        metadata={{ coverImage: image }}
        imageCss={{ backgroundColor: brandConfig.theme.colors.liteWhite }}
      />
    </Popup>
  )
}

export default memo(ViewEarnReward)
