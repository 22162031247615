import React, { useCallback, useState } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

import EarnPoints from '../components/earnPoints/EarnPoints'
import { DEFAULT_CONTAINER_STATE } from '../../../constants/containerStates'
import { openBlankRedirectPage } from '../../../util/app/app.helpers'
import ViewEarnReward from '../components/earnPoints/ViewEarnReward'

const EarnPointsContainer = ({
  tokenProfile,
  mainProfile: { brandAppConfig }
}) => {
  const { t } = useTranslation()

  const [popupState, setPopupState] = useState({
    id: DEFAULT_CONTAINER_STATE.NONE,
    data: null
  })

  const handleSelectOption = useCallback((option) => {
    setPopupState({
      id: EARN_POINTS_STEP.SHOW_EARN_OPTION,
      data: option
    })
  }, [])

  const handleNoPopup = () => {
    setPopupState({ data: null, id: DEFAULT_CONTAINER_STATE.NONE })
  }

  const handlePopupSubmit = () => {
    switch (popupState.id) {
      case EARN_POINTS_STEP.SHOW_EARN_OPTION:
        openBlankRedirectPage(popupState.data.link)
        break
      default:
        handleNoPopup()
        break
    }
  }

  const renderPopup = () => {
    switch (popupState.id) {
      case EARN_POINTS_STEP.SHOW_EARN_OPTION:
        return (
          <ViewEarnReward
            onSubmit={handlePopupSubmit}
            t={t}
            onCancel={handleNoPopup}
            {...popupState.data}
          />
        )

      default:
        break
    }
  }

  return (
    <>
      <EarnPoints
        cashbackEnabled={brandAppConfig.cashbackEarnOption}
        tokenProfile={tokenProfile}
        t={t}
        onSelectOption={handleSelectOption}
      />
      {renderPopup()}
    </>
  )
}

const mapStateToProps = ({ mainReducer }) => {
  const { mainProfile, tokenProfile } = mainReducer
  return {
    mainProfile,
    tokenProfile
  }
}

export default connect(mapStateToProps, null)(EarnPointsContainer)

const EARN_POINTS_STEP = {
  SHOW_EARN_OPTION: 'showEarnOption'
}
