import React, { memo } from 'react'
import {
  BWLink,
  BWPanelContent,
  BWText,
  BWIcon
} from 'src/libs/qb-brand-web-components'
import { Link } from 'react-router-dom'
import { faBullseyeArrow } from '@fortawesome/pro-light-svg-icons'

import { EARN_POINTS_URL } from '../../../constants/navigation'
import Button from '../../../components/shared/button/Button'
import BalanceWithAnimation from '../../../components/shared/overview/BalanceWithAnimation'
import OverviewMainPanelContent from '../../../components/overview/OverviewMainPanelContent'

const OverviewMainPanelWrapper = ({
  onEnterRewardCodeClick,
  currentUserPoints,
  previousUserPoints,
  t,
  ...restProps
}) => {
  return (
    <OverviewMainPanelContent t={t} {...restProps}>
      <Link to={EARN_POINTS_URL}>
        <Button
          label={t('brand:welcome.earn-points')}
          type='button'
          customCss={{ height: 72, fontSize: '1.3rem' }}
        />
      </Link>
      <BWPanelContent css={{ gap: 5 }}>
        <BWIcon
          icon={faBullseyeArrow}
          faIconCss={{ fontSize: 60, marginBottom: 15 }}
        />
        <BWText label={t('brand:reward.current-point-balance')} size={21} />
        <BalanceWithAnimation
          previousUserPoints={previousUserPoints}
          currentUserPoints={currentUserPoints}
          fontSize={32}
        />
      </BWPanelContent>
      <BWText
        alignCenter
        label={
          <>
            <BWText label={t('brand:welcome.have-a-reward-code')} />
            <BWLink
              label={t('brand:welcome.receive-your-reward')}
              onClick={onEnterRewardCodeClick}
            />
          </>
        }
        as='div'
      />
    </OverviewMainPanelContent>
  )
}

export default memo(OverviewMainPanelWrapper)
