import React, { memo } from 'react'
import { BWPageContent, BWPanelContent } from 'src/libs/qb-brand-web-components'
import { faCoins } from '@fortawesome/pro-light-svg-icons'

import customConfig from 'customConfig'
import PageHeaderWrapper from 'wrappers/PageHeaderWrapper'
import HowToEarnRewardsWrapper from 'wrappers/HowToEarnRewardsWrapper'
import BuyPointsLink from '../../../../components/popups/reward/BuyPointsLink'
import CashbackLink from '../../../../components/popups/reward/CashbackLink'
import Panel from '../../../../components/shared/panel/Panel'

const EarnPoints = ({
  tokenProfile: { name, symbol, enablePurchasePoints },
  cashbackEnabled,
  t,
  onSelectOption
}) => {
  return (
    <>
      <PageHeaderWrapper
        label={t('brand:welcome.earn-points')}
        icon={faCoins}
        description={t(customConfig.howManyPointsYouEarnLabel, {
          tokenName: name
        })}
        header
      />
      <BWPageContent css={{ paddingTop: '0 !important' }}>
        <HowToEarnRewardsWrapper
          t={t}
          tokenSymbol={symbol}
          fullWidth
          onSelectOption={onSelectOption}
        />
        <Panel>
          <BWPanelContent>
            {enablePurchasePoints && <BuyPointsLink t={t} />}
            {cashbackEnabled && <CashbackLink t={t} />}
          </BWPanelContent>
        </Panel>
      </BWPageContent>
    </>
  )
}

export default memo(EarnPoints)
