import { DYNAMIC_PAGE_REDIRECT_PATHS } from '../../../constants/externalUrls'
import { REDIRECT_URL } from '../../../constants/navigation'
import { isUrlPatternMatching, toLower } from '../../../util/string.helpers'
import { EAGLES_REF } from '../constants/app'

const truthbrushUrl = () => {
  return `${REDIRECT_URL}/${DYNAMIC_PAGE_REDIRECT_PATHS.TRUTHBRUSH}`
}

const zDentalUrl = () => {
  return `${REDIRECT_URL}/${DYNAMIC_PAGE_REDIRECT_PATHS.Z_DENTAL}`
}

const buildRedemptionUrl = (url, authId) => {
  if (isUrlPatternMatching(url, 'moda.truthbrush.com')) {
    return truthbrushUrl()
  } else if (isUrlPatternMatching(url, 'myzsonic.com/moda')) {
    return zDentalUrl()
  } else if (isUrlPatternMatching(url, 'order.iredeemhealth.com')) {
    return iRedeemEarnUrl(authId)
  }

  return url
}

const isWelcomeRefMatching = (currentRef) => {
  return EAGLES_REF.some((ref) => toLower(ref) === toLower(currentRef))
}

const IREDEEEM_BASE_URL = 'https://order.iredeemhealth.com/site/modahealth'

const iRedeemEarnUrl = (authId) => {
  return `${IREDEEEM_BASE_URL}?member_id=${authId}`
}

export {
  IREDEEEM_BASE_URL,
  truthbrushUrl,
  zDentalUrl,
  buildRedemptionUrl,
  isWelcomeRefMatching,
  iRedeemEarnUrl
}
